import { Store } from 'react-notifications-component'

export const showSnackbarNotification = (
  title,
  message,
  type,
  timeout = 3000
) => {
  Store.addNotification({
    title: title,
    message: typeof message === 'string' ? message : message.message || message,
    type: type || 'default',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: timeout,
      onScreen: true
    }
  })
}

export const showSnackbarError = (error, timeout) => {
  let message = ''
  if (typeof error === 'string') {
    message = error
  } else {
    message =
      error?.response?.data?.Message ||
      error?.response?.data?.message ||
      error?.message ||
      error?.Message ||
      'Ocurrió un error'
  }

  console.error(error)
  return showSnackbarNotification('Error', message, 'danger', timeout)
}

export const showSnackbarSuccess = (
  message,
  title = 'Operación exitosa',
  timeout
) => showSnackbarNotification(title, message, 'success', timeout)

export const showSnackbarInfo = (message, title = 'Información', timeout) =>
  showSnackbarNotification(title, message, 'info', timeout)
